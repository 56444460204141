import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🎞️ Films`}</h1>
    <p>{`Para aprender francés existen varios métodos complementarios. Te invitamos a sumergirte en un entorno francófono a través de
actividades culturales y de entretenimiento. Te sugerimos revisar esta selección de películas por las que puedes empezar.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/L%C3%A9on"
      }}><strong parentName="a">{`Léon`}</strong></a></h2>
    <p>{`El corazón de un asesino profesional se ablanda gracias a su experiencia con una huérfana que quiere aprender su oficio.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/4wqjZaA0N6s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Am%C3%A9lie"
      }}><strong parentName="a">{`Amélie`}</strong></a></h2>
    <p>{`Se trata de una de las películas de culto del cine de autor francés que sirvió para lanzar la carrera de Audrey Tautou y con una afamada banda sonora creada por Yann Tiersen.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/w_ij-w93PDg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Les_Choristes"
      }}><strong parentName="a">{`Les choristes`}</strong></a></h2>
    <p>{`Clement Mathieu, un profesor de música, empieza a trabajar como vigilante en un internado de reeducación de menores. En sus esfuerzos por acercarse a los chicos se entrega a la tarea de familiarizarlos con la magia del canto.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/OVBgzo5tGp4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Irreversible_(pel%C3%ADcula)"
      }}><strong parentName="a">{`Irréversible`}</strong></a></h2>
    <p>{`El amante de una mujer y su ex novio toman la justicia en sus manos después de que ella se convierte en la víctima de un violador.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/wlzi50Efjoo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Micmacs"
      }}><strong parentName="a">{`Micmacs`}</strong></a></h2>
    <p>{`Un hombre y su amigo ponen en marcha un plan para hacer estallar dos fábricas de armas, las fábricas que fabricaban la mina que mató a su padre.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/PAyWhcwO_EU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Delicatessen_(pel%C3%ADcula)"
      }}><strong parentName="a">{`Delicatessen`}</strong></a></h2>
    <p>{`En algún lugar del futuro, un grupo de extraños seres subterráneos se resisten al mercado negro de un carnicero caníbal.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3RtGq58LZnE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Pers%C3%A9polis_(pel%C3%ADcula)"
      }}><strong parentName="a">{`Persepolis`}</strong></a></h2>
    <p>{`Una niña iraní atestigua el control fundamentalista de su país y se rebela contra sus políticas estrictas, especialmente las referentes a las mujeres.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/NOUkcLh4y6E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://www.filmaffinity.com/es/film973262.html"
      }}><strong parentName="a">{`Le Mystère Henri Pick`}</strong></a></h2>
    <p>{`Una joven editora descubre una novela magistral escrita por Henri Pick, un cocinero fallecido dos años antes. Según su viuda, Pick jamás leyó un libro
y lo único que escribió en su vida fue la lista de la compra, así que, cuando la novela se convierte en un éxito de ventas, un crítico literario se une
a la hija de Pick para desentrañar el misterio.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/f_MT4QOOpMo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/La_ciudad_de_los_ni%C3%B1os_perdidos"
      }}><strong parentName="a">{`La cité des enfants perdus`}</strong></a></h2>
    <p>{`Un ex cazador de ballenas y su amigo tienen que rescatar a su hermano de un inventor loco que roba los sueños de niños.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/MLfS8Tp9Fsc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      